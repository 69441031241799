import { useEffect, useState } from "react";

import sadFace from "../../images/sad.webp";
import checkout from "../../images/checkout.webp";
import totalspent from "../../images/totalspent.webp";
import reward from "../../images/reward.webp";

import "./css/memberDashboard.css";
import Navbar from "../navbar/navbar";

import { hideElementById } from "../../util/hideShowElementById";
import { getCookie } from "../../util/cookies";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../services/user";

import OrderPreview from "./orderPreview";

function MemberDashboard()
{
    let navigate = useNavigate()

    let [ user, setUser ] = useState({invoices: []});
    let [ totalSpent, setTotalSpent ] = useState(0.00);

    const onLoad = async () => 
    {
        const response = await getUserInfo();

        switch(response.status)
        {
            case 500:
                return "error";
            case 401:
                return "error";
            case 200:
                const tempUser = response.data
                tempUser.invoices.sort((b, a) => new Date(a.invoiceFulfilled) - new Date(b.invoiceFulfilled));

                let tempTotalSpent = 0;
                tempUser.invoices.forEach(invoice => {
                    tempTotalSpent += invoice.invoicePrice;
                });

                setTotalSpent(tempTotalSpent / 100);
                setUser(tempUser);
                break;
        }
    }

    useEffect(() => {
        hideElementById('n-b-top')

        const UTOK = getCookie("UTOK");

        if (!UTOK)
            return navigate("/login")

        const tokenParts = UTOK.split(".");

        const payload = JSON.parse(atob(tokenParts[1]));
        
        if(payload.authorities.includes("ADMIN"))
            return window.location.href = "https://dashboard.altsgreat.com";

        onLoad()
    }, [])

    return (<div>
        <Navbar />
        <div className="membership-dashboard-cont">
            <div className="m-dashboard-info-cont m-dashboard-item">
                <h3 className="m-dashboard-item-header">
                    Account Info
                </h3>
                <div className="m-dashboard-account-info-cont">
                    <div className="m-d-a-i-item">
                        <div className="m-d-a-i-item-img-cont total-spent-img-cont">
                            <img className="m-d-a-i-item-img" src={totalspent}/>
                        </div>
                        <div className="m-d-a-i-item-info">
                            <div className="m-d-a-i-item-header">Amount Spent</div>
                            <div className="m-d-a-i-item-contents">
                                ${totalSpent.toFixed(2)}
                                <div className="hover-box">
                                    <i className="fa fa-question-circle m-d-a-question" aria-hidden="true"/>
                                    <span className="tooltip">The total amount spent</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-d-a-i-item">
                        <div className="m-d-a-i-item-img-cont reward-img-cont">
                            <img className="m-d-a-i-item-img" src={reward}/>
                        </div>
                        <div className="m-d-a-i-item-info">
                            <div className="m-d-a-i-item-header">Store Credit</div>
                            <div className="m-d-a-i-item-contents">
                                ${(user.credits / 100).toFixed(2)}
                                <div className="hover-box">
                                    <i className="fa fa-question-circle m-d-a-question" aria-hidden="true"/>
                                    <div className="tooltip">Can be applied to any purchase. Must have at least $5.00 to use.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-d-a-i-item">
                        <div className="m-d-a-i-item-img-cont">
                            <img className="m-d-a-i-item-img" src={checkout}/>
                        </div>
                        <div className="m-d-a-i-item-info">
                            <div className="m-d-a-i-item-header">Total Orders</div>
                            <div className="m-d-a-i-item-contents">
                                {user.invoices.length}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-dashboard-orders-cont m-dashboard-item">
                <h3 className="m-dashboard-item-header">
                    Your Orders
                </h3>
                <div className="m-dashboard-orders">
                    {
                        user.invoices.length > 0 ? user.invoices.map(order => {
                            return <OrderPreview order={order}/>
                        }) :
                        <div className="m-dashboard-no-orders-cont">
                            <img className="m-dashboard-no-orders-img" src={sadFace}/>
                            <div className="m-dashboard-no-orders-msg">No orders, browse products <Link className="s-c-is-browse-products-link" to={"/"}>here</Link></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>)
}

export default MemberDashboard