import { Link } from "react-router-dom";
import AccountPreview from "./accountPreview";
import "./statfinder.css"

import { useEffect, useState } from "react"
import ActiveFilter from "./activeFilter";
import { hideElementById } from "../../util/hideShowElementById";
import { getAccountProducts } from "../../services/product";

function StatsFinder()
{
    const [product, setProduct] = useState({})
    const [allAccounts, setAllAccounts] = useState([])
    const [accounts, setAccounts] = useState([])

    const [selectedGamemode, setSelectedGamemode] = useState()

    const orderAmount = 1

    const onload = async () =>
    {
        if (accounts.length === 0) {
            const retrievedAccounts = await getAccountProducts();
            if(retrievedAccounts.length == 0)
            {
                document.getElementById("sf-no-matches").style.display = "block"
                hideElementById("sf-accounts")
            }
            setAllAccounts(retrievedAccounts)
            setAccounts(retrievedAccounts)
        }
        document.querySelector("#navbar-cont").style.zIndex = 0;
    }

    useEffect(() => {
        hideElementById("pn-nav-bar");
    }, [])

    useEffect(() =>
    {
        onload()

    }, [])

    return (
        <div id="statfinder-cont">
            <div className="statfinder-modal">
                <div className="stats-header-cont">
                    <h3>Hypixel Stats Account Finder</h3>
                    <Link to={"/"} className="stats-finder-buttons">Back to Store</Link>
                </div>
                <div className="sf-nav">
                    <div className="sf-nav-gamemodes">
                        <div className={`sf-nav-item ${selectedGamemode === "megawalls" ? "gamemode-selected" : ""}`}>3 Char</div>
                        <div className={`sf-nav-item ${selectedGamemode === "bedwars" ? "gamemode-selected" : ""}`} >Bedwars</div>
                        <div className={`sf-nav-item ${selectedGamemode === "skywars" ? "gamemode-selected" : ""}`} >Skywars</div>
                        <div className={`sf-nav-item ${selectedGamemode === "pit" ? "gamemode-selected" : ""}`} >Pit</div>
                        <div className={`sf-nav-item ${selectedGamemode === "megawalls" ? "gamemode-selected" : ""}`}>Skyblock</div>
                        <div className={`sf-nav-item ${selectedGamemode === "megawalls" ? "gamemode-selected" : ""}`}>Lunar Cosmetics</div>
                        <div className={`sf-nav-item ${selectedGamemode === "megawalls" ? "gamemode-selected" : ""}`}>Multi Capes</div>
                        <div className={`sf-nav-item ${selectedGamemode === "megawalls" ? "gamemode-selected" : ""}`}>Other</div>
                    </div>
                    <div className="sf-nav-results">
                        <div>
                            <b>Results:</b> {accounts.length}
                        </div>
                    </div>
                </div>
                <div className="sf-main-cont">
                    <div id="sf-no-matches">
                        No Results Found
                        <p>Please try another search</p>
                    </div>
                    <div id="sf-accounts" className="sf-accounts-preview-cont">
                        {accounts.map((product) => {
                            return <AccountPreview product={product} setProduct={setProduct}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatsFinder