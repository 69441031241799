import "./orderpage.css"

import { useEffect, useState }              from "react";

import { hideElementById, showElementById } from "../../util/hideShowElementById";
import { getInvoiceById }                   from "../../services/invoice";
import { convertToFormattedDateTime }       from "../../util/helperFunctions";

import $ from "jquery"

import Securer from "./securer/Securer";
import OrderItem from "./orderItem";
import { AppConstants } from "../../constants/AppConstants";

export default function OrderPage()
{
    const [ activeSerial, setActiveSerial ] = useState("");
    const [ orderItems, setOrderItems ]     = useState("");
    const [ serials, setSerials ]           = useState("");
    const [ instructions, setInstructions ] = useState("There are no specific instructions for this product. If you are unsure how to use it then join the discord and create a ticket for \"Issue with Order\"");

    const [order, setOrder] = useState({
        product: {}
    })

    const onload = async () =>
    {
        const invoiceId = window.location.pathname.split("/")[2];

        const response = await getInvoiceById(invoiceId);

        switch (response.status)
        {
            case 401:
                return setOrderItems("Open link from your email. If issue persist, join the discord server and create a ticket.");
            case 500:
                return setOrderItems("Internal Server Error. This is an issue on our end. Join the discord and create a ticket.");
            default:
                const order = response.data;
                setOrder(order);

                let tempSerials;

                if(!order.product)
                {
                    switch (order.invoiceStatus) {
                        case "Not Paid":
                            $("#order-status").addClass("unpaid");
                            return setOrderItems("Order has not been paid for. If you paid, refresh the page after a minute. If that doesn't work then join the Discord server and create a ticket");
                        case "Revoked":
                            $("#order-status").addClass("restricted");
                            return setOrderItems("Order has been revoked. If you believe this was an error, contact \"Contact us via Discord\"");
                        case "Restricted":
                            $("#order-status").addClass("restricted");
                            return setOrderItems("Order marked as fraud or revoked. Join the discord server and make a #ticket to resolve this issue.");
                        case "Fulfilled":
                            $("#order-status").addClass("restricted");
                            return setOrderItems("Order has already been fulfilled");
                        case "Paid":
                            $("#order-status").addClass("paid");

                            const serials = order.serials;
                            const shoppingCartItems = order.shoppingCart.shoppingCartItems;

                            tempSerials = shoppingCartItems.map((shoppingCartItem) =>
                            {
                                shoppingCartItem.serials = [];
                                serials.forEach((serial) => {
                                    if (serial.productId === shoppingCartItem.product.productId) {
                                        shoppingCartItem.serials.push(serial)
                                    }
                                })

                                return <OrderItem key={shoppingCartItem.shoppingCartItemId} shoppingCartItem={shoppingCartItem} setSerials={setSerials} setActiveSerial={setActiveSerial} setInstructions={setInstructions}/>
                            })

                            setOrderItems(tempSerials);
                            break;
                        default:
                            return setOrderItems("Internal Server Error. This is an issue on our end. Join the discord and create a ticket.");
                    }
                }else{
                    console.log(order)
                    order.serials.forEach(serial => {
                        tempSerials += serial.serialId;
                    })
                    setOrderItems(tempSerials)
                }
        }
    }

    useEffect(() =>
    {
        hideElementById('pn-nav-bar');
        hideElementById('n-b-top');

        onload();
    }, [])

    const goBack = () => {
        showElementById("items-preview-cont", "flex");
        hideElementById("serial-cont");
        hideElementById("full-access-serial-msg");
        hideElementById("boost-container");
    }

    return (
        <div className="i-p-page">
            <div className="i-p-cont">
                <div className="i-p-order-details">
                    <div className="i-p-d-p-h">
                        <h3 className="i-p-o-d-h">Order Id: <span className="i-p-o-d-h-id">{order.invoiceId}</span></h3>
                        <div id="order-status">{order.invoiceStatus}</div>
                    </div>
                    <div className="i-p-details">
                        <div className="i-p-o-d-cont">
                            <div id="items-preview-cont" className="items-preview-cont">
                                {orderItems}
                            </div>
                            <div id="serial-cont" className="i-p-o-d-serials">
                                <div className="i-p-o-d-s-g-back" onClick={goBack}><i className="fa fa-angle-left" /> Go Back</div>
                                <div>
                                    <div className="fasm-body">
                                        <div>
                                            <div className="fasm-note-title">INSTRUCTIONS</div>
                                            <div className="fasm-note">
                                                {instructions}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="boost-container"></div>
                                {serials}
                            </div>
                        </div>
                        <div className="i-p-info">
                            <div className="i-p-o-i-cont">
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Amount
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        ${(order.invoicePrice / 100).toFixed(2)}
                                    </h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Email
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        {order.email}
                                    </h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Payment Method
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        {order.paymentMethod}
                                    </h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Order Created
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        {convertToFormattedDateTime(order.invoiceCreated)}
                                    </h4>
                                </div>
                                <div className="i-p-o-d-actions">
                                    <a href={AppConstants.DISCORD_REGULAR_INVITE} target="_blank"><button className="i-p-o-d-action action-discord"><i className="fab fa-discord" /> Contact Support</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Securer activeSerial={activeSerial} />
        </div>
    )
}