import fabanned from '../images/fabanned.webp'
import faunbanned from '../images/faunbanned.webp'
import fanostats from "../images/javabedrock.webp"

import falvl21 from '../images/falvl21.webp'

import famvpplus from '../images/famvpplus.webp'
import famvpplus100 from "../images/famvpplus100.webp"
import famvpplus250 from "../images/famvpplus250.webp"

import famvp from '../images/famvp.webp'

import favipplus from '../images/favipplus.webp'

import favip from '../images/favip.webp'

import faskyblocklvl7 from '../images/faskyblocklvl7.webp'
import faskyblocklvl50 from '../images/faskyblocklvl50.webp'

import skyblockcoins from "../images/skyblockcoins.webp"

import famigrator from "../images/famigrator.webp"
import favanilla from "../images/favanilla.webp"
import facherryblossom from "../images/facherryblossom.webp"
import faeyeblossom from "../images/faeyeblossom.webp"
import famcc from "../images/famcc.webp"

import xboxgamepass from "../images/xboxgamepass.webp"

export const ShopProducts = new Map([
    // Hypixel Banned Full Access
    ["banned-fa", {
        "description": "Permanent access to Minecraft Premium and unbanned from all servers except Hypixel.",
        "title": "Hypixel Banned Full Access",
        "imageUrl": fabanned,
        "enhancedDescription": "Permanent premium Minecraft full access accounts that you can transfer to your own email and fully customize. These accounts are guaranteed unbanned on all servers except for Hypixel. You will be the only owner of the account and it will be yours forever. Since we are so confident in this product, we offer a lifetime warranty in case of any faults or issues with the product.",
        "productNameId": "banned-fa",
        "minimum": 1,
        "price": 11.99
    }],
    
    // Hypixel Unbanned FA
    ["unbanned-fa", {
        "description": "Permanent access to Minecraft Premium and unbanned from all servers.",
        "title": "Hypixel Full Access Unbanned",
        "imageUrl": faunbanned,
        "enhancedDescription": "Permanent premium Minecraft full access accounts that you can transfer to your own email and fully customize. These accounts are unbanned on all servers including Hypixel. You will be the only owner of the account and it will be yours forever. Since we are so confident in this product, we offer a lifetime warranty in case of any faults or issues with the product.",
        "productNameId": "unbanned-fa",
        "minimum": 1,
        "price": 12.99
    }],

    ["java-bedrock-key", {
        "description": "Minecraft [License Key] Java + Bedrock Edition",
        "title": "Minecraft Java + Bedrock Edition Key",
        "enhancedDescription": "These are keys to redeem Minecraft with Java + Bedrock edition on your Microsoft account. This is not an account, but a key that will provide you a permanent license to own a copy of the game. This is exactly like buying Minecraft from the official store except it is 30% off. There is no risk of losing access whatsoever.",
        "imageUrl": fanostats,
        "productNameId": "java-bedrock-key",
        "minimum": 1,
        "price": 19.99
    }],

    // Level 21+ FA
    ["level-fa", {
        "description": "Account with a level 20 or higher on Hypixel. Chance to come with ranks and skyblock profiles.",
        "title": "Hypixel Level 21+ Full Access",
        "imageUrl": falvl21,
        "enhancedDescription": "Permanent premium Minecraft full access accounts that you can transfer to your own email and fully customize. These accounts are unbanned and leveled 21 or higher on Hypixel and since Hypixel security bans accounts under level 21 when a flagged IP is used, these accounts have 0 chance of being security banned.",
        "productNameId": "level-fa",
        "minimum": 1,
        "price": 16.99
    }],

    // Level 21 Ranked
    ["lvl-vip-fa", {
        "description": "Account with a level 21+ and ranked on Hypixel. Ranks are VIP.",
        "title": "VIP Ranked Level 21+ Full Access",
        "imageUrl": favip,
        "enhancedDescription": "These come with VIP rank and are level 21 or over on the server Hypixel. The accounts have a chance of having high stats on different gamemodes on the server.",
        "productNameId": "lvl-vip-fa",
        "minimum": 1,
        "price": 19.99
    }],

    ["lvl-vip+-fa", {
        "description": "Account with a level 21+ and ranked on Hypixel. Ranks are VIP+.",
        "title": "VIP+ Ranked Level 21+  Full Access",
        "imageUrl": favipplus,
        "enhancedDescription": "These accounts come with VIP+ rank and are level 21 or over on the server Hypixel. The accounts have a chance of having high stats on different gamemodes on the server.",
        "productNameId": "lvl-vip+-fa",
        "minimum": 1,
        "price": 21.99
    }],

    ["lvl-mvp-fa", {
        "description": "Account with a level 21+ and ranked on Hypixel. Ranks are MVP.",
        "title": "MVP Ranked Full Access",
        "imageUrl": famvp,
        "enhancedDescription": "These accounts come with MVP rank and are level 21 or over on the server Hypixel. The accounts have a chance of having high stats on different gamemodes on the server.",
        "productNameId": "lvl-mvp-fa",
        "minimum": 1,
        "price": 27.99
    }],

    // MVP+ Full Access
    ["mvp+-fa", {
        "description": "Accounts unbanned from Hypixel with special MVP+ rank on the server.",
        "title": "Hypixel MVP+ Full Access",
        "imageUrl": famvpplus,
        "enhancedDescription": "These accounts are the same as Hypixel FA Unbanned except they have a special MVP+ rank on Hypixel which cost $44.99. Both the rank and account is permanent and the same as buying it from the official stores except it is insanely discounted.",
        "productNameId": "mvp+-fa",
        "minimum": 1,
        "price": 37.99
    }],

    // Skyblock Full Access
    ["skyblock-fa", {
        "description": "Account that are level 7+ on Hypixel Skyblock to access Bazaar.",
        "title": "Skyblock Level 7+ Full Access",
        "imageUrl": faskyblocklvl7,
        "enhancedDescription": "These accounts are for players who want to start a new Skyblock profile without the initial grind. Level 7 allows Skyblock players to access Bazaar which is a crucial feature in Skyblock.",
        "productNameId": "skyblock-fa",
        "minimum": 1,
        "price": 15.99
    }],

    ['skyblock-50-fa', {
        "description": "Accounts level 50+ on Hypixel Skyblock",
        "title": "Skyblock Level 50+ Full Access",
        "enhancedDescription": "These accounts are for players who want to start a progressed Skyblock profile with a 1 billion daily trade limit for coins. High chance of account having 100+ million networth.",
        "productNameId": "skyblock-50-fa",
        "imageUrl": faskyblocklvl50,
        "minimum": 1,
        "price": 29.99
    }],

    ["14d-gamepass-promo", {
        "description": "14 day gamepass that you can set your own username.",
        "title": "14 Day Gamepass Promos",
        "imageUrl": xboxgamepass,
        "enhancedDescription": "14 day xbox gamepass promo that you can set your own username for. These promos will create a brand new account for 14 days so they are unbanned from all servers after you redeem the promo. Each promo takes around 1-2 minutes to redeem then after that you can log into anywhere and play on any server you want.",
        "productNameId": "14d-gamepass-promo",
        "minimum": 6,
        "price": 0.85
    }],

    // Fresh Full Access
    ["fresh-fa", {
        "description": "Permanent Full Access that never played on Hypixel.",
        "title": "No Stat Full Access Java + Bedrock",
        "imageUrl": fanostats,
        "enhancedDescription": "These accounts have no stats on Hypixel. Fresh stats and unbanned from all servers. 100% customizable and permanently yours with lifetime warranty. These profiles may have usernames already set on them.",
        "productNameId": "fresh-fa",
        "minimum": 1,
        "price": 14.99
    }],

    ['mvp+-fa-100', {
        "description": "Accounts unbanned from Hypixel with special MVP+ rank on the server.",
        "title": "Hypixel Level 100+ MVP+ Full Access",
        "enhancedDescription": "These accounts are the same as Hypixel FA Unbanned except they have a special MVP+ rank and are level 100 or higher on Hypixel's network (Not Skyblock). Both the rank and account is permanent and the same as buying it from the official store except it is insanely discounted.",
        "productNameId": "mvp+-fa-100",
        "imageUrl": famvpplus100,
        "minimum": 1,
        "price": 47.99
    }],

    ['mvp+-fa-250', {
        "description": "Accounts unbanned from Hypixel with special MVP+ rank on the server.",
        "title": "Hypixel Level 250+ MVP+ Full Access",
        "enhancedDescription": "These are full access accounts that are unbanned on Hypixel and have a network level of 250 or higher. They can come with Lunar/Badlion cosmetics and generally have a lot of progess on Hypixel.",
        "productNameId": "mvp+-fa-250",
        "imageUrl": famvpplus250,
        "minimum": 1,
        "price": 129.99
    }],

    ['migrator-cape-fa', {
        "description": "Full Access with Migrator Capes",
        "title": "Migrator Cape Full Access",
        "enhancedDescription": "This product is full access meaning all information is customizable including the email of the account. The profiles are unbanned from all servers and contains a rare cape only given to early Migrators of Minecraft. These capes are super rare and guaranteed to last permanently.",
        "productNameId": "migrator-cape-fa",
        "imageUrl": famigrator,
        "minimum": 1,
        "price": 15.99
    }],

    ['skyblock-coins', {
        "description": "Skyblock Coins on Hypixel",
        "title": "Skyblock Coins",
        "enhancedDescription": "Skyblock coins for Hypixel. The price listed is the price per million coins and the minimum amount to purchase is 250 million. These coins are 100% authentic and not obtained in any way that breaks Hypixel rules. However, irl trading is still not allowed on Hypixel. Having an account with a higher skill level on Skyblock will tremendously decrease the chance of being banned for irl trading.",
        "productNameId": "skyblock-coins",
        "imageUrl": skyblockcoins,
        "minimum": 250,
        "price": 0.07
    }],

    ['vanilla-cape-fa', {
        "description": "Full Access with Vanilla Cape",
        "title": "Vanilla Cape Full Access",
        "enhancedDescription": "These profiles come with a super rare cape called the Vanilla Cape. The capes are only given to a few individuals who purchased Minecraft in the early days of the game. These profiles are also 100% customization and all information can be changed to your own. Comes with both Java and Bedrock and extremely discounted.",
        "productNameId": "vanilla-cape-fa",
        "imageUrl": favanilla,
        "minimum": 1,
        "price": 17.99
    }],

    ['mcc-cape-fa', {
        "description": "Full Access with MCC 15th Year Cape",
        "title": "MCC 15th Year Cape Full Access",
        "enhancedDescription": "These profiles come with a super rare cape called the MCC 15th Year Cape. The capes are only given to a few individuals who participated in the Minecraft Championships (MCC) event on Bedrock. These profiles are also 100% customization and all information can be changed to your own. Comes with both Java and Bedrock and extremely discounted.",
        "productNameId": "mcc-cape-fa",
        "imageUrl": famcc,
        "minimum": 1,
        "price": 34.99
    }],
    
    ['cherry-blossom-fa', {
        "description": "Full Access with Cherry Blossom Cape",
        "title": "Cherry Blossom Full Access",
        "enhancedDescription": "These profiles come with a super rare cape called the Cherry Blossom cape. The capes are only given to a few individuals who voted for a mob. It is the newest cape from Mojang and extremely rare. These profiles are also 100% customization and all information can be changed to your own. Comes with both Java and Bedrock version and unbanned from all servers including Hypixel.",
        "productNameId": "cherry-blossom-fa",
        "imageUrl": facherryblossom,
        "minimum": 1,
        "price": 24.99
    }],

    ['eye-blossom-fa', {
        "description": "Full Access with Eye Blossom Cape",
        "title": "Eye Blossom Full Access",
        "enhancedDescription": "These profiles come with a super rare cape called the Eye Blossom cape. The cape was only available for a 10 day period before Christmas of 2024 and was only obtainable by purchasing a creeper cape from the Minecraft store. These profiles are 100% customization and all information can be changed to your own. Comes with both Java and Bedrock version and unbanned from all servers including Hypixel.",
        "productNameId": "eye-blossom-fa",
        "imageUrl": faeyeblossom,
        "minimum": 1,
        "price": 34.99
    }],

    ['xbox-gamepass-accounts', {
        "description": "Xbox Gamepass Accounts",
        "title": "Xbox Gamepass Accounts",
        "enhancedDescription": "Ready to play, premade, Xbox gamepass accounts. These are email:pass accounts that have are ready to play. You can log in anywhere with email and password and they are unbanned from all server. They will last from 7 - 14 days, but guaranteed to last for at least 7 days. After the 14 day time period the account will lose access to gamepass and also Minecraft. The range 7 - 14 days depends on when we stocked.",
        "productNameId": "xbox-gamepass-accounts",
        "imageUrl": xboxgamepass,
        "minimum": 4,
        "price": .99
    }]
])