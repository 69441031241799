import { addAlert } from "../../util/addAlert";

export default function FullAccessSerial({ serial, setActiveSerial })
{

    const dateUploaded = serial.dateUploaded;

    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(e.target.innerText)
        addAlert(`Copied ${e.target.innerText}`, "success", 3000)
    }

    const openSecurerModal = () => {
        document.getElementById("securer-cont").style.display = "block";
        setActiveSerial(serial.serialId);
    }

    return (
        <div className="fasm-alt">
            {!serial.claimedEmail && dateUploaded > "2024-07-02T20:52:01.278334" ? 
            (
                <div className="fasm-alt-cont">
                    <div className="fasm-info-cont">
                        <div>
                            <div className="fa-info-title">Username</div>
                            <div className="fa-info-details">
                                <div className="content">
                                    <span className="text-to-copy" onClick={copyToClipboard}>{serial.serialId.split(" - ")[1] ? serial.serialId.split(" - ")[1].split(" ")[0] : "Unknown"}</span>
                                    <span id="clipboardIcon" className="clipboard-icon fade-in"><i className="fas fa-copy" /></span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="fa-info-title">Instructions</div>
                            <div className="fa-info-details">
                                <div className="content">
                                    <a className="text-to-copy" href="https://youtu.be/T9lAHSF2Hx8" target="_blank">Open Instructions</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {serial.serialId.split(" - ")[1] ? 
                    (<div className="fasm-alt-actions">
                        <div className="fasm-alt-claim-now" onClick={openSecurerModal}>
                            Claim On Your Email
                        </div>
                    </div>) 
                    : 
                    (<div className="fasm-alt-actions">
                        <div className="fasm-alt-claimed">
                            Create ticket on Discord
                        </div>
                    </div>)}
                </div>
            ) 
            :
            (
                <div>
                    {dateUploaded > "2024-07-02T20:52:01.278334" ?
                    ( 
                        <div className="fasm-alt-cont">
                            <div className="fasm-info-cont">
                                <div>
                                    <div className="fa-info-title">Claimed Email</div>
                                    <div className="fa-info-details">
                                        <div className="content">
                                            <span className="text-to-copy" onClick={copyToClipboard}>{serial.claimedEmail}</span>
                                            <span id="clipboardIcon" className="clipboard-icon fade-in"><i className="fas fa-copy" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fasm-alt-actions">
                                <div className="fasm-alt-claimed">
                                    Claimed
                                </div>
                            </div>
                        </div>
                    ) 
                    :
                    (
                        <div className="fasm-alt-cont">
                            <div className="fasm-info-cont">
                                <div>
                                    <div className="fa-info-title">Account Details</div>
                                    <div className="fa-info-details">
                                        <div className="content">
                                            <span className="text-to-copy" onClick={copyToClipboard}>{serial.serialId}</span>
                                            <span id="clipboardIcon" className="clipboard-icon fade-in"><i className="fas fa-copy" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>)
}