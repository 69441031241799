import { Route, Routes } from 'react-router-dom';

import TOS from './tos';
import Privacy from './privacy';

import "./help.css"
import Navbar from '../navbar/navbar';
import { useEffect } from 'react';
import ReturnPolicy from './returns';
import { hideElementById } from '../../util/hideShowElementById';
import Footer from '../footer/footer';

function Help()
{
    useEffect(() => {
        hideElementById('n-b-top')
    }, [])

    return (
        <div className="help-cont">
            <Navbar />
            <Routes>
                <Route path='/return-policy' element={<ReturnPolicy/>}/>
                <Route path="/terms-of-service" element={<TOS />}/>
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
            <Footer/>
        </div>
    );
}

export default Help;
