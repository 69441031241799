import SlideShow from "./slideshow";

import transdiscord from '../../images/discord.webp'
import alexandsteve from '../../images/alexsteve.webp'

import { AppConstants } from "../../constants/AppConstants.js";

function SlideShowCont()
{
    return (
        <div className="welcome-cont">
            <div id="slideshow-cont">
                <SlideShow
                    id="slideshow-one"
                    href={AppConstants.WEB_URL}
                    img={alexandsteve}
                    header="Welcome to GreatAlts"
                    msg="We offer lifetime warranties, the cheapest prices and the highest quality keys and alts. Shop with us today!" />

                <SlideShow
                    id="slideshow-two"
                    href={AppConstants.DISCORD_REGULAR_INVITE}
                    img={transdiscord}
                    header="Join our Discord"
                    msg="Our discord server provides support for customers and anyone who has any questions about our products or services. We provide occasional updates, discounts and giveaways! Join now for free!" />
            </div>
        </div>
    )
}

export default SlideShowCont;