import "./css/login.css"

import { useEffect, useState } from "react";

import Navbar from "../navbar/navbar";

import { hideElementById, showElementById } from "../../util/hideShowElementById";
import { Link, useNavigate } from "react-router-dom";
import { addAlert } from "../../util/addAlert";
import { loginUser } from "../../services/user";
import { getCookie, setCookie } from "../../util/cookies";
import RegistrationSuccess from "./registrationSuccess";
import { ShopInfo } from "../../constants/ShopInfo";

function Login()
{
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    // Setup navigation to different route
    let navigate = useNavigate();

    useEffect(() => {
        // console.log(history)
        hideElementById('pn-nav-bar');
        hideElementById('n-b-top');

        if(getCookie("UTOK"))
            navigate("/users")
    }, [])

    /**
     * Handles the login form submission
     * 
     * @param {*} e The submit form event
     */
    const handleSubmit = async (e) => 
    {
        // Prevent the default form reloading page.
        e.preventDefault();

        if(email === "" || password === "")
            return addAlert("Enter Email and Password", "error", 4000)

        let response = await loginUser({
            email: email,
            password: password
        })

        if(response.status === 401)
            return addAlert("Email or Password is incorrect.", "error", 4000)
        if(!response.data)
            return addAlert("Unknown Error, Contact Support.", "error", 4000)
        if(!response.headers.authorization)
            return addAlert("Unknown Error, Contact Support.", "error", 4000)

        let token = response.headers.authorization;

        const responseData = response.data;
        if(!responseData.verified)
        {
            setCookie("RTOK", token, 30);
            hideElementById("member-login-cont");
            return showElementById('verify-cont', 'flex');
        }else{
            setCookie("UTOK", token, 30);

            const tokenParts = token.split(".");

            const payload = JSON.parse(atob(tokenParts[1]));
            
            if(payload.authorities.includes("ADMIN"))
                return window.location.href = "https://dashboard.altsgreat.com";
    
            navigate(-1)
        }
    }

    return (
    <div>
        <Navbar />
        <form id="member-login-cont" className="member-login-cont" onSubmit={handleSubmit}>
            <div className="s-r-header-cont">
                <h3 className="s-r-header">Sign In</h3>
                <p className="s-r-h-msg">View your orders and rewards!</p>
            </div>
            <div className="s-r-input-cont">
                <h4 className="s-r-input-header">Email</h4>
                <input className="s-r-input" type="email" onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="s-r-input-cont">
                <h4 className="s-r-input-header">Password</h4>
                <input className="s-r-input" type="password" onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <Link to={"/forgot-password"} className="s-r-forgot-password">
                Forgot Password?
            </Link>
            <input className="s-r-submit-btn" value="Sign In" type="submit"/>
            <div className="s-r-footer">
                {/* New to {ShopInfo.CompanyName}? <Link to={"/register"} className="s-r-switch">Register Now</Link> to join the millions of users and earn cashback on every purchase! */}
                New to {ShopInfo.CompanyName}? <Link to={"/register"} className="s-r-switch">Register Now</Link> to join the millions of users and track every single purchase!
            </div>
        </form>
        <RegistrationSuccess email={email}/>
    </div>)
}

export default Login;