import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function NotFound()
{
    return (
        <>
            <Helmet>
                <title>404 | Great Alts | MC Alt Shop</title>
                <meta name="description" content={`Page Not Found`} />
            </Helmet>
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <h1>404 - Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
                <Link to="/">Go Home</Link>
            </div>
        </>)
};