import { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { addAlert } from "../../util/addAlert";

import ProductSection from "./ProductSection";

import "./product.css"
import ProductDetails from './productDetails';
import { showElementById } from '../../util/hideShowElementById';
import { addItemToShoppingCart } from '../../services/shoppingCart';
import ShoppingCartAdded from '../shoppingcart/shoppingCartAdded';
import { useCurrency } from '../../context/currencyContext';
import { useShoppingCart } from '../../context/shoppingCartContext';
import { getProductByNameId } from '../../services/product';
import { ShopProducts } from '../../constants/AccountsInfo';
import { useNavigate } from 'react-router-dom';

function ProductPage()
{
    const productNameId = window.location.pathname.split("/")[2]

    const { currency } = useCurrency()

    const { setShoppingCartItemCount } = useShoppingCart();

    const [ product, setProduct ]                   = useState({});
    const [ orderAmount, setOrderAmount ]           = useState(1);
    const [ instructions, setInstructions ]         = useState("");
    const [ features, setFeatures ]                 = useState([]);
    const [ warrantyFeatures, setWarrantyFeatures ] = useState([]);
    const [ notes, setNotes ]                       = useState([]);

    const navigate = useNavigate();

    const onLoad = async () => 
    {
        setProduct(ShopProducts.get(productNameId));

        if (productNameId.includes("-fa")) {
            setInstructions("Upon purchase, you will be instructed to provide an email which will be used to log into the Minecraft account. You will also receive a complete step by step guide on how to securely transfer the email to your personal email.")
            setFeatures(['Java + Bedrock Version', 'Permanent Access', '100% Customizable', 'Instant Email Delivery', '24/7 Customer Service', 'Lifetime Warranty'])
            setWarrantyFeatures(['Lifetime Warranty']);
            setNotes(["Permanent", "Instant Delivery", "Lifetime Warranty", "Full Access"]);
        } else if (productNameId === "java-bedrock-key") {
            setInstructions("Upon purchase, you will receive a step by step guide on how to redeem the key on your own personal Minecraft account.")
            setFeatures(['Java + Bedrock Edition', 'Permanent Access', '100% Customizable', 'Instant Email Delivery', '24/7 Customer Service', 'Lifetime Warranty'])
            setWarrantyFeatures(['Lifetime Warranty']);
            setNotes(["Permanent", "Instant Delivery", "Lifetime Warranty", "Redeem Key"]);
        } else if (productNameId === "skyblock-coins") {
            setFeatures(['Must be at least level 50 on Skyblock.', 'Not Duped/Fake. 100% Authentic and Legit coins.', 'Instant Email Delivery', '24/7 Customer Service'])
            setInstructions('After purchase you will be prompted to join the discord server and provide your in game username which the coins will be transferred to.')
            setWarrantyFeatures([`Due to the nature of Hypixel's rules. There is no warranty.`])
            setNotes(["Manual Delivery"]);
        } else if (productNameId === "xbox-gamepass-accounts") {
            setInstructions("Upon purchase, you will be redirected to the order link where you will view your product. They will be email:pass and able to log into anywhere. You must set the name of the accounts before playing on some clients.");
            setFeatures(['Full Access', 'Will last 7 - 14 Days', 'Instant Email Delivery', '24/7 Customer Service']);
            setWarrantyFeatures(['7 day warranty'])
            setNotes(["Instant Delivery", "7 Day Warranty", "Unbanned Everywhere"]);
        }

        let response;
        try{
            response = await getProductByNameId(productNameId)

            if(response.data)
            {
                const retrievedProduct = response.data;
                ShopProducts.get(productNameId).quantity = retrievedProduct.quantity;
                setOrderAmount(retrievedProduct.minimum)
            }
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => 
    {
        if(!ShopProducts.get(productNameId))
            navigate("/404");

        if (window.innerWidth > 1025)
            showElementById('pn-nav-bar');

        onLoad();
    }, [])

    const addToCart = async () => 
    {
        if(orderAmount < product.minimum)
            return addAlert(`Minimum order amount is ${product.minimum}`, "warning", 3000)
        if(orderAmount > product.quantity)
            return addAlert(`Not enough stock to fulfill order`, "warning", 3000)

        const productNameId = window.location.pathname.split("/")[2]

        const shoppingCartItemInfo =
        {
            productNameId: productNameId,
            quantity: orderAmount
        }

        const response = await addItemToShoppingCart(shoppingCartItemInfo);

        if(response.status === 201)
        {
            showElementById("shopping-cart-added-cont");
            showElementById("shopping-cart-item-added", "flex");

            setShoppingCartItemCount(response.data.itemsCount)
        }else{
            console.log(response.data)
            if(response.data.message === "NOT_ENOUGH_STOCK")
                return addAlert(`Not enough stock to fulfill order`, "warning", 3000);
            if(response.data.message.includes("_MAXIMUM"))
                return addAlert(`Product already added to cart and the maximum per user is ${response.data.split("_MAXIMUM")}`, "warning", 3000);
            return addAlert("Could not add product to shopping cart", "error", 4000);
        }
    }

    const changeQuantity = (e) => 
    {
        let oAmount = parseInt(orderAmount);

        if (e.target.id === "i-quantity") {
            if (product.quantity > oAmount)
                oAmount += 1
        } else {
            if (product.minimum < oAmount)
                oAmount -= 1
        }
        setOrderAmount(oAmount)
    }

    const onChange = (e) => {
        setOrderAmount(e.target.value)
    }

    return (
        <div>
            <Helmet>
                <title>{`${product.title}`} | Cheap Minecraft Microsoft Alts</title>
                <meta name="description" content={`Shop and purchase for your ${product.title} from us.`} />
                {product.imageUrl && (
                    <link rel="preload" as="image" href={product.imageUrl} />
                )}
            </Helmet>
            <div className='product-page-cont'>
                <div className="p-p-main">
                    <div className='product-img-cont'>
                        <img src={product.imageUrl} className="product-image" alt={product.description} />
                    </div>
                    <div className="p-p-m-info">
                        <div className="p-p-m-i-cont">
                            <h1 className="p-p-m-header">{product.title}</h1>
                            <ol className="p-p-m-bonus">
                                {
                                    notes.map(note => <li>{note}</li>)
                                }
                            </ol>
                            <article className="p-p-m-description">{product.enhancedDescription}</article>
                        </div>
                        {/* <div className="full-access-addons">                            
                            <div className="fa-addons-options-cont">
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Prename</p>
                                    <p className="fa-addon-option-price">+ $2.00</p>
                                </div>
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Migrator Cape</p>
                                    <p className="fa-addon-option-price">+ $2.00</p>
                                </div>
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Vanilla Cape</p>
                                    <p className="fa-addon-option-price">+ $4.00</p>
                                </div>
                            </div>
                        </div> */}
                        <div className='s-c-p-prices'>
                            <div className='s-c-p-price-cont'>
                                <div className='s-c-p-price-display'>
                                    <span className='s-c-p-main-price'>
                                        {currency.symbol}{((product.price * currency.rate) * orderAmount).toFixed(2)}
                                    </span>
                                    <span className='s-c-p-dashed-price'>
                                        {currency.symbol}{((product.price * currency.rate) * orderAmount * 1.35).toFixed(2)}
                                    </span>
                                </div>
                                <div className='s-c-p-percent-off'>
                                    - 35%
                                </div>
                            </div>
                            <div className='s-c-p-discount-list'>

                            </div>
                            <div className='s-c-p-rewards'>

                            </div>
                        </div>
                        <div className="button-div">
                            <div className="s-c-p-quantity-setter">
                                <button type="button" className="s-c-p-q-b" id="d-quantity" onClick={changeQuantity}>-</button>
                                <input className="s-c-p-quantity-i" placeholder="1" onChange={onChange} value={orderAmount} type="number" />
                                <button type="button" className="s-c-p-q-b" id="i-quantity" onClick={changeQuantity}>+</button>
                            </div>
                            <button className="purchase" onClick={addToCart}>Add To Cart</button>
                        </div>
                    </div>
                </div>
                <div className="p-p-extra">
                    <div className="p-p-product-details-header">More Info</div>
                    <div className='p-p-info-mini'>
                        <ProductDetails instructions={instructions} features={features} warrantyFeatures={warrantyFeatures}/>
                    </div>
                </div>
            </div>
            <ShoppingCartAdded />
            <ProductSection />
        </div>
    );
}

export default ProductPage;