import { useEffect, useState } from "react";
import { ShopProducts } from "../../constants/AccountsInfo"
import { hideElementById, showElementById } from "../../util/hideShowElementById"

import FullAccessSerial from "./fullAccessSerial";
import questionmark from "../../images/questionmark.webp";

export default function OrderItem({shoppingCartItem, setSerials, setActiveSerial, setInstructions})
{
    const [ productImage, setProductImage ] = useState("");

    useEffect(() => 
    {
        const product = shoppingCartItem.product;
        const productNameId = product.productNameId;

        if(ShopProducts.get(shoppingCartItem.product.productNameId))
        {
            setProductImage(ShopProducts.get(shoppingCartItem.product.productNameId).imageUrl);
        }else{
            if(productNameId.includes("-account"))
            {
                setProductImage(`https://crafatar.com/avatars/${shoppingCartItem.product.account.uuid}?overlay`);
            }else{
                setProductImage(questionmark);
            }
        }
    }, [])

    const openOrderDetails = () => 
    {
        const product = shoppingCartItem.product;
        const serials = shoppingCartItem.serials;

        const productNameId = product.productNameId;

        hideElementById("items-preview-cont");
        showElementById("serial-cont", "flex");

        let tempSerials;

        if(productNameId === "skyblock-coins")
        {
            tempSerials = "This product needs to be manually delivered. Join the discord and create a ticket for \"Issue with Order\" to claim."
        } else if (serials.length < 1) {
            tempSerials = "Seems like there are no more accounts in stock. This can happen if two people purchased the last account in stock at the same time. Please create a ticket for \"Issue With Order\" in the discord server."
        } else {
            if (productNameId.includes("-fa") || (productNameId.includes("-account") && !productNameId.includes("-accounts")))
            {
                tempSerials = serials.map(serial => {
                    return <FullAccessSerial serial={serial} setActiveSerial={setActiveSerial} />
                })

                setInstructions(`Click "Open Instructions" to view how to claim your account. For any issues click "Contact Support" and we will try to fix your issue as soon as possible.`)
            } else {
                if(productNameId === "java-bedrock-key")
                    setInstructions(`Go to https://redeem.microsoft.com -> login with a Microsoft account that does not own Minecraft -> Enter the XXXXX-XXXXX-XXXXX-XXXXX-XXXXX code you received -> Click "Next" -> Now you own a copy of Minecraft.`)
                if(productNameId === "xbox-gamepass-accounts")
                    setInstructions(`Go to https://minecraft.net -> Click "Profile Name" -> Set the username of the account before playing on any clients`)
                let counter = 1;
                tempSerials = serials.map(serial => {
                    return <div><b>{counter++}:</b> {serial.serialId}</div>
                })
            }
        }
        setSerials(tempSerials);
    }

    return (
        <div className="m-orders-preview">
            <div className="m-orders-info">
                <div className="m-order-preview-info-cont">
                    <div className="m-order-preview-img-cont">
                        <img className="m-order-preview-img" src={productImage} alt="Product Thumbnail"/>
                    </div>
                    <div>
                        <div className="m-order-preview-info">
                            {shoppingCartItem.product.title}
                        </div>
                    </div>
                </div>
                <div className="m-order-preview-actions">
                    <div className="m-order-preview-action-item" onClick={openOrderDetails}>View Order</div>
                </div>
            </div>
        </div>
    )
}