import './store.css';

import { useEffect }              from "react";
import { Helmet }                 from 'react-helmet';

import { getCookie, setCookie }   from '../util/cookies';
import { Route, Routes }          from 'react-router-dom';
import { useCurrency }            from '../context/currencyContext';
import { getShoppingCart }        from '../services/shoppingCart';
import { useShoppingCart }        from '../context/shoppingCartContext';
import { getLocalStorageItem }    from '../util/localstorage';

import SlideShowCont              from "./slideshow/SlideShowCont";
import ProductSection             from "./product/ProductSection";

import ProductPage                from './product/ProductPage';
import StatsFinder                from './statfinder/statfinder';
import OrderPage                  from './orderpage/orderpage';
import ShoppingCart               from './shoppingcart/shoppingCart';
import ShoppingCartAdded          from './shoppingcart/shoppingCartAdded';
import SkyblockCoinsPage          from './product/skyblock/skyblock';

function Store()
{
  const { changeCurrency } = useCurrency();
  const { setShoppingCartItemCount } = useShoppingCart();
  
  function checkCookie(cookieName)
  {
    return document.cookie.split(';').some(cookie =>
    {
      return cookie.trim().startsWith(cookieName + '=');
    });
  }

  async function onload() 
  {
    let referrer;

    const response = await getShoppingCart();

    setShoppingCartItemCount(response.data.itemsCount);

    // Check who referred the user to the store
    if (getLocalStorageItem("visited"))
    {
      referrer = "default"
      setCookie("referrer", referrer, 14);
      window.localStorage.removeItem("visited")
    } else if (!checkCookie("referrer")) {
      if (window.location.pathname && window.location.pathname.includes("r=")) {
        referrer = window.location.pathname.split("r=")[1]
      } else {
        referrer = "default"
      }
      setCookie("referrer", referrer, 14);
    }
  }

  useEffect(() =>
  {
    // addAlert("Site was recently updated and is under maintainence. If you face any errors join the discord and create a ticket.", "error", 7000)

    onload()

    let i = 0;

    let intervals = setInterval(() => {

      if (document.getElementById("slideshow-one"))
      {
        let slideshowids = ["slideshow-one", "slideshow-two"]

        document.getElementById(slideshowids[i % slideshowids.length]).style.display = "none"
        document.getElementById(slideshowids[(i + 1) % slideshowids.length]).style.display = "flex"
        if (i === slideshowids.length - 1) {
          i = 0;
        } else {
          i++;
        }
      } else {
        clearInterval(intervals)
      }
    }, 10000)

    const currency = getCookie("currency");
    if(!currency)
    {
      setCookie("currency", "USD", 30);
    }else{
      changeCurrency(currency);
    }
  }, [])


  return (
    <div className="store-cont">
      <Routes>
        <Route path="/*" element={<div>
          <Helmet>
            <title>Buy Minecraft Accounts | Cheap Alt Shops | Full Access Accounts</title>
          </Helmet>
          <SlideShowCont />
          <ProductSection/>
        </div>}/>
        <Route path="/shoppingcart" element={<ShoppingCart />} />
        <Route path="/products/skyblock" element={<SkyblockCoinsPage/>} />
        <Route path="/products/*" element={<ProductPage />} />
        <Route path="/statsfinder" element={<StatsFinder />} />
        <Route path="/invoice/*" element={<OrderPage />} />
        <Route path="/order/*" element={<OrderPage />} />
      </Routes>
      <ShoppingCartAdded/>
    </div>
  );
}

export default Store;
