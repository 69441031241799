import "./hoverFooter.css"

import Discord              from "../../images/discord.webp"
import { AppConstants }     from "../../constants/AppConstants";

function HoverFooter()
{
    return (
        <div className="hover-footer">
            <div className="hf-items-cont">
                <a href={AppConstants.DISCORD_REGULAR_INVITE} target="_blank" className="hf-item">
                    <img className="hf-img" src={Discord} alt="Discord Support Icon"/>
                </a>
            </div>
        </div>
    )
}

export default HoverFooter;