import { useEffect, useState } from "react"

import Product from './Product.jsx';

import { ShopProducts } from "../../constants/AccountsInfo.js";

import { showElementById } from "../../util/hideShowElementById.js";
import { Link } from "react-router-dom";

import skyblock from "../../images/skyblockcoins.webp";
import Magnify from "../../images/mag.webp";
import { AppConstants } from "../../constants/AppConstants.js";
import { useCurrency } from "../../context/currencyContext.js";

function ProductSection()
{
    const { currency } = useCurrency();
    
    const [ products, setProducts ] = useState(ShopProducts)

    useEffect(() => 
    {
        if (window.innerWidth > 1025)
            showElementById('pn-nav-bar');
        showElementById('n-b-top');

        setProducts(ShopProducts)
    }, [products])

    return (
        <div id='store'>
            <h2 id="mc-fa" className="section-header">Minecraft Best Sellers</h2>
            <div className='store-grid-container'>
                <Link className="stats-finder-item" to={`/statsfinder`}>
                    <div className='stats-finder-header'>
                        <div className="stats-finder-title">Account Finder</div>
                    </div>
                    <div className="stats-img-holder">
                        <img src={Magnify} alt=""/>
                    </div>
                    <div className='stats-finder-info'>
                        <div className="stats-mini-details">
                            <h3 className="stats-finder-desc-header">What is this?</h3>
                            <div className="stats-finder-description">
                                Search for accounts matching your specifications. Hypixel stats, capes and more filters coming soon!
                            </div>
                            <button className="stats-finder-search">Search</button>
                        </div>
                    </div>
                </Link>
                <Product
                    product={products.get('banned-fa')}/>
                <Product
                    product={products.get('unbanned-fa')}/>
                <Product
                    product={products.get('fresh-fa')}/>
                <Product
                    product={products.get('xbox-gamepass-accounts')}/>
            </div>
            <h2 id="hypixel-fas" className="section-header hypixel-alts">Hypixel Full Access</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('mvp+-fa')}/>
                <Product
                    product={products.get('mvp+-fa-100')}/>
                <Product
                    product={products.get('mvp+-fa-250')}/>
                <Product
                    product={products.get('lvl-mvp-fa')}/>
            </div>
            <div className='store-grid-container'>
                <Product
                    product={products.get('level-fa')}/>
                <Product
                    product={products.get('lvl-vip-fa')}/>
                <Product
                    product={products.get('lvl-vip+-fa')}/>
            </div>
            <h2 id="hypixel-temp" className="section-header">Temporary Alts</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('xbox-gamepass-accounts')}/>
            </div>
            <h2 id="skyblock" className="section-header">Skyblock</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('skyblock-fa')}/>
                <Product
                    product={products.get('skyblock-50-fa')}/>
                <a className="grid-item" href={`${AppConstants.WEB_URL}/products/skyblock`}>
                    <div className="img-holder">
                        <img className="product-thumbnail-img" src={skyblock} alt="Hypixel Skyblock Coins"/>
                    </div>
                    <div className='grid-item-info'>
                        <div className='grid-item-header'>
                            <div className="g-i-h-title">Hypixel Skyblock Coins</div>
                        </div>
                        <div className="product-mini-details">
                            <div className="product-price">
                                {currency.symbol}{(0.045 * currency.rate).toFixed(3)}
                            </div>
                            <div className="product-price-real">
                                {currency.symbol}{0.07}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <h2 id="capes" className="section-header">Capes</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('migrator-cape-fa')}/>
                <Product
                    product={products.get('vanilla-cape-fa')}/>
                <Product
                    product={products.get('cherry-blossom-fa')}/>
                <Product
                    product={products.get('eye-blossom-fa')}/>
                <Product
                    product={products.get('mcc-cape-fa')}/>
            </div>
        </div>
    );
}

export default ProductSection;