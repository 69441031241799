import { hideElementsByClassName, showElementById } from "../../util/hideShowElementById";
import { sendVerificationLink }                     from "../../services/user";
import { addAlert }                                 from "../../util/addAlert";

import { Link }                                     from "react-router-dom";

import mail                                         from "../../images/mail.webp"

import "./css/verify.css"

function RegistrationSuccess({email})
{
    const handleResendVerification = async () =>
    {
        try{
            hideElementsByClassName('verify-status-message')
            const sendStatus = await sendVerificationLink(email)

            switch(sendStatus.data.message)
            {
                case 'RESENT_LINK':
                    return addAlert('Verification email sent! Check your email', 'success', 4000)
                case 'INTERNAL_SERVER_ERROR':
                    return addAlert("Couldn't send verification email. Contact support.", "warning", 4000);
                default:
                    return addAlert("Couldn't send verification email. Contact support.", "error", 4000);
            }
        }catch(err){
            console.log(err);
            showElementById('verification-email-unsuccessful');
        }
    }

    return (
        <div id="verify-cont" className="verify-cont">
            <div className="verify-header-cont">
                <img src={mail} className="verify-img" alt="mail picture"/>
                <h3 className="verify-header">Verify your email address</h3>
                <p className="verify-message">You're almost done! We have sent an email to</p>
                <p className="verify-message"><strong>{email}</strong></p>
            </div>
            <div className="verify-instructions">
                <p className="verify-i-item">Just click on the link in the email to complete verification.</p>
                <p className="verify-i-item">If you don't see it then <strong>check Spam/Junk.</strong></p>
            </div>
            <button className="verify-resend-btn" onClick={handleResendVerification}>Resend Verification Email</button>
            <div className="verify-footer">
                Need Help? <Link to={"/support"} className="verify-contact">Contact Support</Link>
            </div>
        </div>)
}

export default RegistrationSuccess;